/**
 * 此处放置用户相关api
 */
import http from '@/assets/http.js';
import request from "@/assets/request.js";
export default {
  //海南获取什么数据
  queryuserdjjlog(data) {
    return http({
      url: '/api/common/boards/queryuserdjjlog',
      method: 'post',
      data,
      withToken: false,
    });
  },

  // 捞了宝财务应急
  getThirdOrderList(data) {
    return http({
      url: '/api/common/thirdOrder/getThirdOrderList',
      method: 'post',
      data,
      withToken: false,
    });
  },

  /**
   * 用户登录
   * @account    账号
   * @password   密码
   */
  loginIn(data) {
    return http({
      url: '/api/common/backstage/accountLogin',
      method: 'post',
      data,
      withToken: false,
    });
  },
  /**
   * 店铺详情
   */
  shopInfo(data) {
    return http({
      url: '/api/shop/queryMyFarmShop',
      method: 'post',
      data,
    });
  },
  // 修改店铺电话，店铺图片，地址
  updateShopPhoneAndAddress(data) {
    return http({
      url: '/api/shop/updateShopPhoneAndAddress',
      method: 'post',
      data,
    });
  },
  // 发送验证码
  sendVerificationCode(data) {
    return http({
      url: '/api/common/pwd',
      method: 'post',
      data,
    });
  },

  VerificationCodeVerify(data) {
    return http({
      url: 'api/shop/updateShopPhone',
      method: 'post',
      data,
    });
  },
  // 获取厂家星系
  reqGetShop(data) {
    return request({
      url: 'api/merchant/factory-review/get',
      method: 'post',
      data
    });
  },
  // 保存厂家星系
  reqSaveShop(data) {
    return request({
      url: '/api/merchant/factory-review/save',
      method: 'post',
      data
    });
  },
  // 修改厂家星系
  reqUpdateShop(data) {
    return request({
      url: '/api/merchant/factory-review/update',
      method: 'post',
      data
    });
  },
  // 查询银行列表
  reqBankList(data) {
    return request({
      url: 'api/merchant/bank-corporate/list',
      method: 'post',
      data
    });
  },

  // 查询
};
