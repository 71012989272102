/**
 * 此处放置商品相关api
 */
import http from "@/assets/http.js";
export default {
  /**
   * 商品管理->商品列表
   * #@pageNum    页数
   * #@pageSize   每页数量
   * #@userId     用户id
   * @goodsName   商品名称
   * @soldOut     商品审核状态：0下架,1上架审核通过, 2 待审核,3未通过
   */
  manageList(data) {
    return http({
      url: "/api/shop/listYmProductInfo",
      method: "post",
      "Content-Type": "application/json;charset=utf-8",
      data,
    });
  },
  /**
   * 商品管理->商品评价列表
   * #@goodsId    商品id
   */
  queryGoodsComments(data) {
    return http({
      url: "/api/common/product/goods-comments",
      method: "post",
      data,
    });
  },
  /**
   * 商品管理->修改库存
   * #@goodsId    商品id
   */
  updateSpecInfoList(data) {
    return http({
      url: "/api/repository/updateCount",
      method: "post",
      "Content-Type": "application/json;charset=utf-8",
      data: data,
    });
  },
  /**
   * 商品管理->删除商品
   * #@id    商品id
   */
  deleteProduct(data) {
    return http({
      url: "/api/productSpec/deleteProduct",
      method: "post",
      data,
    });
  },
  /**
   * 商品管理->删除评价
   * #@commentsId    评论id
   */
  deleteProductComments(data) {
    return http({
      url: "/api/backstage/deleteProductComments",
      method: "post",
      data,
    });
  },
  /**
   * 商品管理->商品上架或下架
   * #@productId    商品ID
   * #@shelf        1下架 2上架
   */
  putOrOutShelf(data) {
    return http({
      url: "/api/shop/productUpperOrlowerShelf",
      method: "post",
      data,
    });
  },
  /**
   * 根据商品ID批量上架或者下架
   * #@productId    商品ID LIST
   * #@shelf        1下架 2上架
   */
  allUpperOrlowerShelf(data) {
    return http({
      url: "/api/shop/allUpperOrlowerShelf",
      method: "post",
      data,
    });
  },
  /**
   * 商品管理->根据商品ID获取商品所有规格值对应的库存量等信息
   * #@productId    商品ID
   */
  getSpecInfoById(data) {
    return http({
      url: "/api/productRelease/getProductDetail",
      method: "post",
      data,
    });
  },
  /**
   * 商品管理->编辑商品清零规则
   * #@clearRuleDays 清零天数
   * #@productId 商品ID
   */
  editSaleClearRules(data) {
    return http({
      url: "/api/merchant/product/sales-clear-rule/edit",
      "Content-Type": "application/json;charset=utf-8",
      method: "post",
      data,
    });
  },
  /**
   * 商品管理->商品销量清零
   * #@productId 商品ID
   */
  salesReset(data) {
    return http({
      url: "/api/merchant/product/sales-reset",
      "Content-Type": "application/json;charset=utf-8",
      method: "post",
      data,
    });
  },
  /**
   * 商品管理->发布商品
   * #@deliveryType    配送方式：1自提2物流3配送
   * #@detail          商品详情
   * #@goodsSpecFormat 规格详情
   * #@imageUrl        图片地址
   * #@isOne           商品分类：是否是一号店专区商品：1是2不是
   * #@name            商品名称
   * #@productTypeId   商品类型ID
   * #@repositoryList  库存详情
   * #@ship            是否包邮
   * #@shopId          店铺ID
   */
  publish(data) {
    return http({
      url: "/api/product/add",
      "Content-Type": "application/json;charset=utf-8",
      method: "post",
      data,
    });
  },
  /**
   * 商品管理->发布编辑商品
   */
  updateProductRelease(data) {
    return http({
      url: "/api/product/edit",
      "Content-Type": "application/json;charset=utf-8",
      method: "post",
      data,
    });
  },

  /**
   * 商品管理->开售
   */
  updateProductcanBuy(data) {
    return http({
      url: "api/productRelease/updateProductcanBuy",
      method: "post",
      data,
    });
  },
  /**
   * 商品管理->获取商品分类信息
   */
  getClassify() {
    return http({
      url: "/api/common/productRelease/selectProductType",
      method: "post",
    });
  },
  /**
     * 添加砍价商品
     *  |Authorization| token令牌  | header | false |string  |    |
        |beginTime| 商品活动开始时间  | formData | true |string  |    |
        |countdown| 活动倒计时（输入整数，如24，36，12等），不填默认为24  | formData | false |string  |    |
        |endTime| 商品活动结束时间  | formData | true |string  |    |
        |finalPrice| 最终价格(不输入默认为0.01)  | formData | false |string  |    |
        |firstCutRatio| 第一刀砍价比例  | formData | true |string  |    |
        |helpCutNumber| 帮忙砍价人数  | formData | true |string  |    |
        |productId| 活动商品ID  | formData | true |string  |    |
        |repositoryId| 商品库存ID  | formData | true |string  |    |
        |shopId| 店铺ID  | formData | true |string  |    |
     */
  addBargainActivity(data) {
    return http({
      url: "/api/bargainActivity/addBargainActivity",
      method: "post",
      data,
    });
  },
  /**
     * 获取待编辑的砍价商品的信息
     *  | 参数名称         | 参数说明     |     in |  是否必须      |  数据类型  |  schema  |
        | ------------ | -------------------------------- |-----------|--------|----|--- |
        |Authorization| token令牌  | header | false |string  |    |
        |bargainId| 砍价活动商品ID  | formData | true |string  |    |
     */
  editBargainActivity(data) {
    return http({
      url: "/api/bargainActivity/editBargainActivity",
      method: "post",
      data,
    });
  },
  /**
     * 查询可参与活动的商品列表
     * | 参数名称         | 参数说明     |     in |  是否必须      |  数据类型  |  schema  |
        | ------------ | -------------------------------- |-----------|--------|----|--- |
        |pageNum| 分页页数  | formData | true |string  |    |
        |pageSize| 分页条数  | formData | true |string  |    |
        |Authorization| token令牌  | header | false |string  |    |
        |productName| 商品名称  | formData | false |string  |    |
        |shopName| 店铺名称  | formData | false |string  |    |
     */
  queryBargainByName(data) {
    return http({
      url: "/api/bargainUser/queryBargainByName",
      method: "post",
      data,
    });
  },
  /**
     * 查询店铺活动商品列表
     * | 参数名称         | 参数说明     |     in |  是否必须      |  数据类型  |  schema  |
        | ------------ | -------------------------------- |-----------|--------|----|--- |
        |beginTime| 商品活动开始时间  | formData | true |string  |    |
        |endTime| 商品活动结束时间  | formData | true |string  |    |
        |Authorization| token令牌  | header | false |string  |    |
        |bargainState| 活动商品状态（状态：0 禁用， 1 启用）  | formData | false |string  |    |
        |pageNum| 页码，从1开始  | formData | false |string  |    |
        |pageSize| 数量，必须大于0  | formData | false |string  |    |
        |productName| 商品名称  | formData | false |string  |    |
        |shopName| 店铺名称  | formData | false |string  |    |
     */
  queryBargain(data) {
    return http({
      url: "/api/bargainActivity/queryBargain",
      method: "post",
      data,
    });
  },
  /**
     * 
     *| 参数名称         | 参数说明     |     in |  是否必须      |  数据类型  |  schema  |
        | ------------ | -------------------------------- |-----------|--------|----|--- |
        |Authorization| token令牌  | header | false |string  |    |
        |bargainId| 砍价活动商品ID  | formData | true |string  |    |
        |bargainState| 活动商品状态（状态：0下架，1上架）  | formData | true |string  |    |
     */
  updateBargainActivity(data) {
    return http({
      url: "/api/bargainActivity/updateBargainActivity",
      method: "post",
      data,
    });
  },
  /**
     * 活动商品删除
     *| 参数名称         | 参数说明     |     in |  是否必须      |  数据类型  |  schema  |
        | ------------ | -------------------------------- |-----------|--------|----|--- |
        |Authorization| token令牌  | header | false |string  |    |
        |bargainId| 砍价活动商品ID  | formData | true |string  |    |
     */
  deleteBargainActivity(data) {
    return http({
      url: "/api/bargainActivity/deleteBargainActivity",
      method: "post",
      data,
    });
  },
  /**
     * 修改活动商品状态
    | 参数名称         | 参数说明     |     in |  是否必须      |  数据类型  |  schema  |
    | ------------ | -------------------------------- |-----------|--------|----|--- |
    |bargainId| 砍价活动商品ID  | formData | true |string  |    |
    |Authorization| token令牌  | header | false |string  |    |
     */
  updateBargainState(data) {
    return http({
      url: "/api/bargainActivity/updateBargainState",
      method: "post",
      data,
    });
  },
  //选择商品配送方式
  shopSendmsg(data) {
    return http({
      url: "/api/editDeliveryType",
      method: "post",
      data,
    });
  },

  //共享商品-上下架
  shelvesGoods(data) {
    return http({
      url: "/api/common/Center/shelvesGoods",
      method: "post",
      data,
    });
  },

  detailByProductId(data) {
    // 获取商品详情;
    return http({
      url: "/api/product/detailByProductId",
      method: "post",
      "Content-Type": "application/json;charset=utf-8",
      data,
    });
  },
};
